import styles from "./Loading.module.css"

const Loading = ({className}) => {
    return (
        <div className={`${styles.ctn} ${className}`}>
        </div>
    )
}

export default Loading
