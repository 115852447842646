import {useNavigate} from "react-router-dom";
import styles from "./Success.module.css"

const Success = ({className, redirectConfig, message}) => {
    const navigate = useNavigate()

    setTimeout(() => {
        navigate(redirectConfig.to)
    }, redirectConfig.wait)

    return (
        <div className={`${styles.ctn} ${className}`}>
            <h1>{message}</h1>
        </div>
    )
}

export default Success
