import {Outlet, useLocation, useNavigate} from "react-router-dom";
import styles from './App.module.css';
import Logo from "./components/Logo";

function App() {
    const {pathname} = useLocation()
    const navigate = useNavigate()
  return (
    <div className={`${styles.App}`}>
        {
            pathname !== "/" && (<div onClick={() => navigate("/")} className={styles.logoCtn}><Logo className={styles.logo} >Home</Logo></div>)
        }
        <Outlet/>
    </div>
  );
}

export default App;
