import axios from "axios";
const Api = axios.create({
    baseURL: "https://echocustoms.azurewebsites.net/api/",
    headers: {
        "x-functions-key": "A2PCoW7FEmn2x-HXL_bkZIwUzqhwwPU-xGF4_4QdO-toAzFuZpemRw=="
    }
})

export const PostJobRequest = async (payload) => {
    const body = {
        subject: "JobRequest",
        data: payload
    }
    return await Api.post("event", body)
}

export const PostLogin = async (payload) => {
    console.log(`PostLogin: `, payload)
    return new Promise((resolve) => {

        resolve({
            user: 'Travis'
        })
    })
}