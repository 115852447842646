import styles from "./LocationIcon.module.css"

const imgSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Flag_of_Florida.svg/1280px-Flag_of_Florida.svg.png"

const LocationIcon = ({className}) => {
    return (
        <div className={`${styles.ctn} ${className}`}>
            <div className={styles.imgCtn}>
                <img src={imgSrc} alt={"Florida state flag"} />
            </div>
            <div className={styles.textCtn}>
                <p>St.Augustine</p>
                <p>Florida</p>
            </div>
        </div>
    )
}

export default LocationIcon
