import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {PostJobRequest} from "../api";

export const SubmitJobRequest = createAsyncThunk('JobRequest/submit', async (payload, {rejectWithValue}) => {
    try {
        const res = await PostJobRequest(payload)
        return res.data
    } catch (err) {
        console.error(err)
        let error= err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data || error.message)
    }
})

export const SelectJobRequest = (state) => state.jobRequest

const initialState = {
    form: null,
    status: null,
    error: null
}

export const JobRequestSlice = createSlice({
    name: 'jobRequest',
    initialState,
    reducers: {
        ClearJobRequest: (state) => state = initialState
    },
    extraReducers(builder) {
        builder
            .addCase(SubmitJobRequest.pending, (state, action) => {
                state.status = "loading";
                state.form = action.meta.arg;
                state.error = null
            })
            .addCase(SubmitJobRequest.fulfilled, (state, action) => {
                state.status = action.payload.status;
            })
            .addCase(SubmitJobRequest.rejected, (state, action) => {
                console.log(action)
                state.status = "failed";
                state.error = action.payload || "Unknown Error.";
            })
    }
})

// Action creators are generated for each case reducer function
export const { ClearJobRequest } = JobRequestSlice.actions

export default JobRequestSlice.reducer