import {memo} from "react";
import {useNavigate} from "react-router-dom";
import FFLIcon from "../components/FFLIcon";
import LocationIcon from "../components/LocationIcon";
import Logo from "../components/Logo";
import styles from "./Landing.module.css"
import {Carousel} from "react-bootstrap";
import CerakoteImg from "../assets/images/ec_3.jpg";
import LaserImg from "../assets/images/ec_1.jpg";

const Head = ({navigate}) => (
    <div className={styles.head}>
        <div><Logo className={styles.logo}/></div>
        <div className={styles.menu}>
            <button className={styles.btn} onClick={() => navigate("quote")}>
                Get Free Estimate
            </button>
        </div>
    </div>
)

const Services = () => (
    <div className={styles.services}>
        <Carousel
            className={styles.carousel}
            controls={false}
            indicators={false}>
            <Carousel.Item className={styles.carouselItem}>
                <img src={CerakoteImg} alt={"Cerakote 'We The People' Pistol"} />
                <div className={styles.carouselCaption}>
                    <p>Cerakote Customization</p>
                </div>
            </Carousel.Item>
            <Carousel.Item className={styles.carouselItem}>
                <img src={LaserImg} alt={"Laser Engraved BKK Knife"} />
                <div className={styles.carouselCaption}>
                    <p>Laser Engraving</p>
                </div>
            </Carousel.Item>
        </Carousel>
    </div>
)

const Facts = () => (
    <div className={styles.facts}>
        <ul>
            <li><LocationIcon /></li>
            <li><FFLIcon /></li>
        </ul>
    </div>
)

const Landing = () => {
    const navigate = useNavigate()
    return (
        <div className={`${styles.ctn}`}>
            <Facts/>
            <Head navigate={navigate} />
            <Services/>
        </div>
    )
}

export default memo(Landing)