import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../components/Loading";
import Success from "../components/Success";
import {ClearJobRequest, SelectJobRequest, SubmitJobRequest} from "../store/JobRequest";
import styles from "./Quote.module.css"

const Quote = () => {
    const dispatch = useDispatch()
    const {status, error} = useSelector(SelectJobRequest)
    const {
        handleSubmit,
        register
    } = useForm()
    const onSubmit = (data) => {
        dispatch(SubmitJobRequest(data))
    }
    useEffect(() => {
        return () => dispatch(ClearJobRequest())
    },[dispatch])
    if(status === 'success') return <Success redirectConfig={{to:"/", wait: 3000}} message={`Thank you, we'll reach out soon!`} />
    if(status === 'loading') return <Loading />
    return (
        <div className={`${styles.ctn}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formSection}>
                    <h3>Job</h3>
                    <textarea
                        className={styles.jobInput}
                        placeholder="Tell us what you need customized..."
                        {...register("job", {required: true})} />

                    <h3>Contact Info</h3>
                    <input
                        type="text"
                        placeholder="Name"
                        {...register("name", {required: true})} />
                    <input
                        type="email"
                        placeholder="Email"
                        {...register("email", {required: true})} />
                    <input
                        type="tel"
                        placeholder="Phone"
                        inputMode="tel"
                        {...register("phone", {required: true})} />

                    <div className={styles.formLine}>
                        <p>Preferred Contact Method:</p>
                        <div>
                            <div className={styles.checkbox}>
                                <label>Call</label>
                                <input type="checkbox" {...register("preferred.call")}/>
                            </div>
                            <div className={styles.checkbox}>
                                <label>Email</label>
                                <input type="checkbox" {...register("preferred.email")}/>
                            </div>
                            <div className={styles.checkbox}>
                                <label>Text</label>
                                <input type="checkbox" {...register("preferred.text")}/>
                            </div>
                        </div>
                    </div>
                </div>
                {error && <p className={styles.error}>{error}</p>}
                <input type="submit" value="Submit"/>
            </form>
        </div>
    )
}

export default Quote