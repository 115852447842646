import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {PostLogin} from "../api";

export const login = createAsyncThunk('auth/login', async (payload) => {
    return await PostLogin(payload)
})

export const SelectUser = (state) => state.auth.user

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        error: false,
        loading: false
    },
    reducers: {
        logout: (state) => {
            state.user = null
        }
    },
    extraReducers(builder) {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
    }
})

// Action creators are generated for each case reducer function
export const { logout } = AuthSlice.actions

export default AuthSlice.reducer