import styles from "./FFLIcon.module.css"

const iconSrc = "https://www.atf.gov/n/n/sites/default/files/media/2015/09/atf-logo.gif"

const FFLIcon = ({className}) => {
    return (
        <div className={`${styles.ctn} ${className}`}>
            <div className={styles.imgCtn}><img src={iconSrc} alt={"Alcohol Tobacco Firearms seal"}/></div>
            <div className={styles.textCtn}>
                <p>LICENSED</p>
                <p>FFL TYPE 07</p>
            </div>
        </div>
    )
}

export default FFLIcon
