import styles from "./Logo.module.css";
import LogoImg from "../assets/images/logo_0_transparent.png"

const Logo = ({className, onClick}) => {
    return (
        <div className={`${styles.ctn} ${className}`} onClick={onClick}>
            <img className={`${styles.img}`} src={LogoImg} alt={"Echo Customs logo"} />
        </div>
    )
}

export default Logo