import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter, Route, Routes} from "react-router-dom";
import App from './App';
import RequireAuth from "./components/RequireAuth";
import Landing from "./pages/Landing";
import Quote from "./pages/Quote";
import store from './store';
import {Provider} from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


const pages = [
    {
        name: 'quote',
        Comp: Quote
    }
]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <HashRouter>
          <Routes>
              {/*<Route path="/login" element={<Login />} />*/}
              <Route path="/" element={<RequireAuth><App pages={pages} /></RequireAuth>}>
                  <Route index element={<Landing />} />
                  {
                      pages.map(({name, Comp}) => {
                          return <Route key={name} path={name} element={<Comp />} />
                      })
                  }
              </Route>
          </Routes>
      </HashRouter>
      </Provider>
  </React.StrictMode>
);
