// import {useSelector} from "react-redux";
// import {Navigate, useLocation} from "react-router-dom";
// import {SelectUser} from "../store/Auth";

const RequireAuth = ({ children }) => {
    // const location = useLocation()
    // const user = useSelector(SelectUser);
    // if(!user) return <Navigate to={"/login"} state={{from: location}} replace />
    return children;
}

export default RequireAuth